<template>
  <div class="bookmark-list" :style="{'--minGridItemWidth': `${MIN_GRID_ITEM_WIDTH}px`}">
    <template v-if="!loading || bookmarks.length">
      <DynamicScroller
        ref="scroller"
        :items="items"
        :min-item-size="minItemSize"
        key-field="_id"
        class="scroller"
        page-mode
        @resize="onScrollerResize"
        @visible="onScrollerMounted"
      >
        <template v-if="isGridLayout" #default="{ item: chunk, index, active }">
          <DynamicScrollerItem
            :item="chunk"
            :active="active"
            :data-index="index"
            class="grid-row pb-6"
          >
            <BookmarkItem
              v-for="(bookmark, i) in chunk.bookmarks"
              :key="`grid-row-item--${i}`"
              :bookmark="bookmark"
              :list-style="listStyle"
              :user-id="userId"
              @action="$emit('action', bookmark, $event)"
              @filter-tags="$emit('filter-tags', $event)"
            />
          </DynamicScrollerItem>
        </template>

        <template v-else #default="{ item: bookmark, index, active }">
          <DynamicScrollerItem
            :item="bookmark"
            :active="active"
            :data-index="index"
            class="pb-6"
          >
            <!-- NEVER key direct children of DynamicScrollerItem -->
            <BookmarkItem
              :bookmark="bookmark"
              :list-style="listStyle"
              :user-id="userId"
              @action="$emit('action', bookmark, $event)"
              @filter-tags="$emit('filter-tags', $event)"
            />

            <v-divider class="mt-6 neutral50" />
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </template>

    <div v-else class="loaders" :class="{'grid-row': isGridLayout}">
      <template v-for="i in 15">
        <BookmarkSkeleton :key="`loader-${i}`" :list-style="listStyle" />
        <v-divider :key="`loader-${i}-divider`" class="my-6 neutral50" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import chunk from 'lodash.chunk'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'

import { BOOKMARK_STYLES } from '@/utils/constants'
import { debounce } from '@/utils/helpers'
import BookmarkItem from './BookmarkItem.vue'
import BookmarkSkeleton from './BookmarkSkeleton.vue'

const GRID_GAP = 32

export default {
  components: {
    BookmarkItem,
    BookmarkSkeleton,
    DynamicScroller,
    DynamicScrollerItem,
  },
  props: {
    loading: Boolean,
    bookmarks: {
      type: Array,
      required: true,
    },
    userId: {
      type: String,
      default: null,
    },
    listStyle: {
      type: String,
      default: 'card',
    },
  },
  data() {
    return {
      scrollerWidth: 0,
    }
  },
  computed: {
    ...mapGetters({
      isExtensionPopup: 'device/isExtensionPopup',
    }),
    MIN_GRID_ITEM_WIDTH() {
      if (this.isExtensionPopup) return 240
      return this.listStyle === BOOKMARK_STYLES.tiles ? 240 : 300
    },
    isGridLayout() {
      return [BOOKMARK_STYLES.cards, BOOKMARK_STYLES.tiles].includes(this.listStyle) && this.$vuetify.breakpoint.smAndUp
    },
    minItemSize() {
      switch (this.listStyle) {
        case BOOKMARK_STYLES.cards:
          return 300
        case BOOKMARK_STYLES.tiles:
          return 240
        case BOOKMARK_STYLES.list:
          return 80
        case BOOKMARK_STYLES.headlines:
          return 90
        default:
          return 0
      }
    },
    items() {
      if (this.isGridLayout) return this.gridChunks
      return this.bookmarks
    },
    gridChunks() {
      const { scrollerWidth, MIN_GRID_ITEM_WIDTH } = this
      if (!scrollerWidth) return []

      let itemsPerRow = Math.floor(scrollerWidth / MIN_GRID_ITEM_WIDTH)
      const totalItemGap = Math.max(2, itemsPerRow - 1) * GRID_GAP

      const isOverflowing = (itemsPerRow * MIN_GRID_ITEM_WIDTH) + totalItemGap > scrollerWidth
      if (isOverflowing) itemsPerRow -= 1

      const chunks = chunk(this.bookmarks, itemsPerRow)

      return chunks.map((set, i) => ({
        _id: `chunk--${i}`,
        id: `chunk--${i}`,
        bookmarks: set,
      }))
    },
  },
  methods: {
    updateScrollerWidth() {
      const $scroller = this.$refs.scroller?.$el
      if (!$scroller) return

      const width = $scroller?.clientWidth ?? 0

      if (this.scrollerWidth !== width) {
        this.scrollerWidth = width
      }
    },
    onScrollerMounted() {
      this.updateScrollerWidth()
    },
    onScrollerResize: debounce(async function onScrollerResize() {
      await this.$nextTick()
      this.updateScrollerWidth()
    }, 50),
  },
}
</script>

<style lang="scss" scoped>
  .grid-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--minGridItemWidth), 1fr));
    grid-gap: 32px;

    &.loaders {
      .v-divider {
        display: none;
      }
    }
  }
</style>
